<template>
    <div class="order-form">
        <!-- 标题 -->
        <div class="order-form-title">填写/选择相关信息</div>
        <!-- 个人联系方式 -->
        <el-form
            :model="form"
            :inline="true"
            size="small">
            <!-- 姓名 -->
            <el-form-item
                label="姓名：">
                <el-input
                    v-model="form.name"
                    placeholder="请输入您的姓名"
                    class="order-form-input">
                </el-input>
            </el-form-item>
            <!-- 联系电话 -->
            <el-form-item
                label="联系电话：">
                <el-input
                    v-model="form.phone"
                    placeholder="请输入您的联系电话"
                    class="order-form-input">
                </el-input>
            </el-form-item>
            <!-- 所在城市 -->
            <el-form-item
                label="所在城市：">
                <area-cascader
                    v-model="form.city"
                    type='text'
                    :level="1"
					:data="$pcaa"
                    placeholder="请选择所在城市"
                    class="order-form-area">
                </area-cascader>
            </el-form-item>
        </el-form>
        <!-- 其它 -->
        <el-form
            :model="form"
            size="small"
            label-width="90px"
            label-position="left">
            <!-- 详细地址 -->
            <el-form-item
                label="详细地址：">
                <el-input
                    v-model="form.address"
                    placeholder="请输入您的详细地址"
                    class="">
                </el-input>
            </el-form-item>
            <!-- 备注 -->
            <el-form-item
                label="备注：">
                <el-input
                    v-model="form.remark"
                    placeholder="请输入您的备注"
                    class="">
                </el-input>
            </el-form-item>
        </el-form>
        <!-- 注意 -->
        <div class="order-form-remark" v-if="orderType=='wallpaper'">
            *用户交付定金后，平台会派专人联系以及上门量房量取实际墙面面积。确定了实用面积后，用户需再次支付订单（扣除定金以外的总费用）
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            userPhone: {
                type: String,
                default: ''
            },
            userName: {
                type: String,
                default: ''
            },
            userArea: {
                type: Array,
                default() {return []}
            },
            userAddress: {
                type: String,
                default: ''
            },
			userAddress: {
			    type: String,
			    default: ''
			},
			orderType: {
			    type: String,
			    default: ''
			}
        },
        data() {
            return {
                form: {
                    name: "",
                    phone: "",
                    city: [],
                    address: "",
                    remark: ""
                }
            }
        },
        watch: {
            userPhone: {
                handler: function(val) {
                    this.form.phone = val;
                },
                immediate: true
            },
            userName: {
                handler: function(val) {
                    this.form.name = val;
                },
                immediate: true
            },
            userArea: {
                handler: function(val) {
                    this.form.city = val;
                },
                immediate: true,
                deep: true
            },
            userAddress: {
                handler: function(val) {
                    this.form.address = val;
                },
                immediate: true
            },
        }
    }
</script>

<style scoped>
.order-form{
    margin-top: 20px;
    padding: 30px 80px;
    background-color: #fff;
    border-radius: 6px;
}
.order-form >>> .el-input__inner,
.order-form >>> .area-select{
    border-color: var(--color);
}
.order-form-title{
    margin-bottom: 20px;
    letter-spacing: 2px;
    font-size: 16px;
}
.order-form-remark{
    letter-spacing: 1px;
    color: var(--color);
}
.order-form-input{
    width: 160px;
}
.order-form-area >>> .area-selected-trigger{
    padding-top: 0;
    padding-bottom: 0;
}
</style>